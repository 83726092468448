/* eslint-disable react-hooks/rules-of-hooks */

'use client';

import { Auction, AuctionBid, Item, Offer, OfferTransaction } from '@/api';
import { useFavorite } from '@/hooks/api/useFavorite';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import useAuction from '@/hooks/useAuction';
import { useEffect, useMemo, useState } from 'react';
import i18next from 'i18n';
import { getPoster } from '@/app/utils/item-utils';
import useAuth from '@/contexts/AuthContext';
import useOffers from '@/hooks/useOffers';
import Checkmark from 'public/assets/icons/checkmark.svg';
import ExMark from 'public/assets/icons/exclamation-mark.svg';

import ProductBidModal from '@/components/ecommerce/product/ProductBid/ProductBidModal';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getUserCreditLimitFn } from '@/queries/account';
import { getUserPaymentMethodsFn } from '@/queries/payment';
import ProductOfferModal from '@/components/ecommerce/product/ProductBid/ProductOfferModal';
import ProductOffersModal from '@/components/ecommerce/product/ProductBid/ProductOffersModal';
import { acceptOfferFn, getAllOffersFn, rejectOfferFn } from '@/queries/offer';
import toast from 'react-hot-toast';
import { ErrorHandler } from '@/helpers/ErrorHandler';
import ProductOffersHistoryModal from '@/components/ecommerce/product/ProductBid/ProductOffersHistoryModal';
import { Constants } from '@/helpers/Constants';
import { printShippingLabel } from '@/helpers/ShippingLabel';
import AuctionPricePromotion from '@/contexts/AuctionPricePromotion';
import BidTimer from '../../general/BidTimer';
import Button from '../../general/Button';
import Price from '../../general/Price';
import { ResponsiveImage } from '../../general/ResponsiveImage';
import SmallLogo from '../../general/LogoSmall';
import MinimumOfferSetModal from '../product/ProductBid/MinimumOfferSetModal';

const ProductRow = ({ item, userBid, offer }: { item: Item | undefined; userBid?: AuctionBid; offer?: Offer; }) => {
    if (!item) {
        return null;
    }

    const [showOffers, setShowOffers] = useState<boolean>(false);
    const { currentOffer: loadedOffer, offerPossible, refetchOffer, invalidateQueries } = useOffers(item, true);
    const currentOffer = offer || loadedOffer;

    const router = useRouter();
    const { user } = useAuth();

    const { private: isPrivate, images } = item;

    let { name } = item;

    if (isPrivate && !user) {
        name = i18next.t('item-text.private-title');
    }

    const { add, remove, isFavorite } = useFavorite();
    const { auction, hasAuction, lastBid, isWinning, comingSoon, isLoosing, hasUserAuctionAccess, hasWonAuction } = useAuction(item, userBid);

    const lastUserbid = (userBid && userBid.auction_id === auction?.id ? userBid : undefined) || lastBid;

    const firstImage = useMemo(() => getPoster(images), [images]);
    const renderPrivateImage = (isPrivate && !user);

    const [showBidding, setShowBidding] = useState<boolean>(false);
    const [showSetMinimum, setShowSetMinimum] = useState<boolean>(false);
    const [showOffering, setShowOffering] = useState<boolean>(false);
    const [showOffersHistoryModal, setShowOffersHistoryModal] = useState<boolean>(false);
    const [currentMinOffer, setCurrentMinOffer] = useState(item.min_offer_amount);

    useEffect(() => {
        setCurrentMinOffer(item.min_offer_amount);
    }, [item.min_offer_amount]);

    const { data: accounts } = useQuery({
        queryFn: getUserPaymentMethodsFn,
        queryKey: ['getUserPaymentMethodsFn'],
        enabled: !!user
    });

    const { data: offers } = useQuery({
        queryFn: () => getAllOffersFn(item.id, true),
        queryKey: ['getAllOffersFn', item.id],
        enabled: !!(item.owner_id && item.owner_id === user?.id)
    });

    const { data: creditLimit } = useQuery({
        queryKey: ['getUserCreditLimitFn', auction?.id],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        queryFn: () => getUserCreditLimitFn(auction!.id),
        enabled: !!auction && hasUserAuctionAccess
    });

    const acceptCounterMutation = useMutation({
        mutationFn: acceptOfferFn,
        onSuccess: (response) => {
            invalidateQueries();
            if (response) {
                if (response.is_accepted && response.status === Offer.StatusEnum.CounterAccepted) {
                    toast.success(i18next.t('common:offers.congrats-purchased'));
                }
            }
        },
        onError: async (error) => {
            const transformedError = await ErrorHandler.transformError(error as Request);
            toast.error(transformedError.message);
        },

    });

    const declineOfferMutation = useMutation({
        mutationFn: rejectOfferFn,
        onSuccess: (response) => {
            invalidateQueries();
            if (response) {
                toast.success(i18next.t('common:offers.offer-declined'));
            }
        },
        onError: async (error) => {
            const transformedError = await ErrorHandler.transformError(error as Request);
            toast.error(transformedError.message);
        },

    });

    const itsYourItem = user && item?.owner_id === user?.id;
    const yourTheLastOffer = user && currentOffer?.user_id === user?.id;
    const counterPending = itsYourItem && currentOffer?.status === Offer.StatusEnum.Countered;
    const showCounterToBuyer = !itsYourItem && yourTheLastOffer && currentOffer?.status === Offer.StatusEnum.Countered;
    const auctionEndDate = auction?.status === Auction.StatusEnum.Extended ? auction.extended_bidding_end_date : auction?.end_date;
    const canBid = auction?.status === Auction.StatusEnum.Blind || auction?.status === Auction.StatusEnum.Extended || auction?.status === Auction.StatusEnum.Open;
    const offerPendingPayment = item?.current_offer_transaction && item?.current_offer_transaction.status === OfferTransaction.StatusEnum.Pending && !itsYourItem;
    const offerExpiredOrDeclined = (currentOffer && new Date(currentOffer.expires_at).getTime() < Date.now()) || (currentOffer && [Offer.StatusEnum.Rejected, Offer.StatusEnum.CounterRejected].includes(currentOffer.status));
    let currentBidText: string = i18next.t('item-text.current-bid-text.current-bid');
    let bidTitle: string | undefined = i18next.t('item-text.current-bid-text.open');
    let currentPrice = <Price className={clsx('text-[14px] font-[500]')} state={isWinning === true ? 'positive' : isWinning === false ? 'negative' : undefined} justification="L" number={auction?.current_amount ?? '0'} />;

    switch (auction?.status) {
        case Auction.StatusEnum.NotStarted:
            bidTitle = i18next.t('item-text.coming-soon');
            if (auction.type === Auction.TypeEnum.BuyNow) {
                currentBidText = i18next.t('item-text.sale-starts');
            } else {
                currentBidText = i18next.t('item-text.open-bidding-starts');
            }
            break;
        case Auction.StatusEnum.Extended:
            bidTitle = i18next.t('item-text.current-bid-text.extended-bidding');
            break;
        case Auction.StatusEnum.Blind:
            bidTitle = i18next.t('item-text.current-bid-text.blind-bidding');
            currentPrice = <div className={clsx('text-[1.375rem] font-[700]')}>{i18next.t('general.currency')} --{i18next.t('general.thousands-separator')}---.--</div>;
            break;
        case Auction.StatusEnum.Closed:
        case Auction.StatusEnum.Done:
            bidTitle = i18next.t('product-bid.closed');
            if (isWinning === true) {
                currentBidText = i18next.t('item-text.current-bid-text.your-winning-bid');
            } else if (isWinning === false) {
                currentBidText = i18next.t('item-text.current-bid-text.winning-bid');
            } else {
                currentBidText = i18next.t('item-text.current-bid-text.closing-bid');
            }
            if (hasWonAuction) {
                bidTitle = i18next.t('product-bid.auction-won');
            } break;
        default:
            break;
    }

    const buttons = (
        <>
            {item.check_offers_possible && itsYourItem && offers && offers.length > 0 && !currentOffer && <Button fullWidth onClick={() => setShowOffersHistoryModal(true)}>{i18next.t('common:offers.view-offers')}</Button>}
            {item.check_offers_possible && !itsYourItem && user && !hasWonAuction && (currentOffer?.user_id !== user?.id || offerExpiredOrDeclined) && <Button onClick={() => setShowOffering(true)} fullWidth>{i18next.t('common:offers.make-offer')}</Button>}
            {item.check_offers_possible && !itsYourItem && user && currentOffer?.user_id === user?.id && !offerExpiredOrDeclined && <Button disabled fullWidth>{i18next.t('common:offers.offer-pending')}</Button>}
            {hasAuction && canBid && !item.check_offers_possible && !itsYourItem && auction.type === Auction.TypeEnum.Auction && <Button onClick={() => setShowBidding(true)} fullWidth>{i18next.t('common:button-text.place-bid')}</Button>}
            {offerPendingPayment && <Button href={Constants.Links.LINK_SETTINGS_INVOICES} fullWidth>{i18next.t('common:offers.pay-invoice')}</Button>}
            {itsYourItem && item?.current_offer_transaction?.status === OfferTransaction.StatusEnum.Paid &&
                <Button
                    onClick={() => {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        printShippingLabel(item!.current_offer_transaction!);
                    }}
                    fullWidth>{i18next.t('common:offers.print-shipping-label')}
                </Button>
            }
            <Button href={`/item/${item.slug}`} fullWidth light>{i18next.t('common:offers.view-item')}</Button>
            <Button
                onClick={async (e) => {
                    e?.stopPropagation();
                    if (isFavorite(item?.id)) {
                        remove(item?.id);
                    } else {
                        add(item?.id);
                    }
                }}
                fullWidth
                className="hidden md:flex"
                light>{!isFavorite(item?.id) ? i18next.t('common:button-text.add-to-favorites') : i18next.t('common:button-text.remove-from-favorites')}
            </Button>
        </>
    );

    return (
        <div>
            {itsYourItem && item?.current_offer_transaction?.status === OfferTransaction.StatusEnum.Pending &&
                <div className="flex items-center text-[14px] leading-[14px] py-[10px] px-2 justify-start  mb-4 bg-green4 text-green5 rounded-[8px] mr-[16px] md:mr-0">
                    <span className="w-[24px] font-[500] mr-[5px]"><Checkmark className="text-green5" /></span> {i18next.t('common:offers.congrats-sold')}
                </div>
            }
            {itsYourItem && item?.current_offer_transaction?.status === OfferTransaction.StatusEnum.Paid &&
                <div className="flex items-center  py-[10px] px-2 justify-start  mb-4 bg-grey16 text-black rounded-[8px] mr-[16px] md:mr-0">
                    <div className="flex items-center mr-14 text-[14px] leading-[14px]"><span className="w-[24px] font-[500] mr-[5px]"><ExMark className="text-green5" /></span> {i18next.t('common:offers.in-transaction-print-label')}</div>
                    <div className="min-w-[235px] mr-2"><Button light fullWidth type="button" linkTarget="_blank" href={Constants.Support.RESALE_GUIDE}>{i18next.t('common:offers.view-resale-guide')}</Button></div>
                </div>
            }
            {offerPendingPayment &&
                <div className="flex items-center text-[14px] leading-[14px] py-[10px] px-2 justify-start  mb-4 bg-green4 text-green5 rounded-[8px] mr-[16px] md:mr-0 ">
                    <span className="w-[24px] font-[500] mr-[5px]"><Checkmark className="text-green5" /></span> {i18next.t('common:offers.congrats-purchased')}
                </div>
            }

            {currentOffer && !counterPending && itsYourItem && currentOffer?.status === Offer.StatusEnum.Pending &&
                <div className="bg-grey16 rounded-[8px] p-[16px] mb-[16px] flex md:flex-row flex-col justify-between mr-[16px] md:mr-0">
                    <div className="flex flex-row md:items-center gap-[16px] md:mb-0 mb-[20px]">
                        <ExMark className="text-green5 md:mt-0 mt-[3px]" />
                        <div className="flex flex-col">
                            <span className="text-[14px] font-[500] text-black">{i18next.t('common:offers.offer-received')}</span>
                            <div className="flex flex-row">
                                <span className="text-[14px] font-[500] text-blue12">{i18next.t('common:offers.offer-amount')}: ${Number(currentOffer.amount)} • {i18next.t('common:offers.offer-expires')}:&nbsp;</span>
                                <span className="text-[14px] font-[500]"><BidTimer dueDate={new Date(currentOffer?.expires_at ?? '')} /></span>
                            </div>
                        </div>
                    </div>
                    <Button onClick={() => setShowOffers(true)} className="md:w-[235px] w-full">{i18next.t('common:offers.review-offer')}</Button>
                </div>
            }

            {(showCounterToBuyer || counterPending) &&
                <div className="bg-grey16 rounded-[8px] p-[16px] mb-[16px] flex flex-row justify-between mr-[16px] md:mr-0">
                    <div className="flex flex-row md:items-center gap-[16px] md:mb-0 mb-[20px]">
                        <ExMark className="text-green5 md:mt-0 mt-[3px]" />
                        <div className="flex flex-col">
                            <span className="text-[14px] font-[500] text-black">{i18next.t('common:offers.offer-countered')}</span>
                            <div className="flex flex-row">
                                <span className="text-[14px] font-[500] text-blue12">{i18next.t('common:offers.countered-amount')}: ${Number(currentOffer.counter_amount)} • {i18next.t('common:offers.counter-offer-expires')}:&nbsp;</span>
                                <span className="text-[14px] font-[500]"><BidTimer dueDate={new Date(currentOffer?.expires_at ?? '')} /></span>
                            </div>
                        </div>
                    </div>
                    {counterPending ?
                        <Button disabled>{i18next.t('common:offers.pending-response')}</Button> :
                        <div className="flex flex-row gap-[8px] [&_button]:!min-w-[100px]">
                            <Button fullWidth loading={acceptCounterMutation.isLoading} onClick={() => acceptCounterMutation.mutateAsync(currentOffer.id)}>{i18next.t('common:offers.accept')}</Button>
                            <Button light lightGray fullWidth disabled={acceptCounterMutation.isLoading} onClick={() => declineOfferMutation.mutateAsync(currentOffer.id)}><span className="text-red">{i18next.t('common:offers.decline')}</span></Button>
                        </div>
                    }
                </div>
            }

            <div className="border-b-[1px] border-grey14 pb-[32px] mb-4 md:mb-[32px] pr-2 md:pr-0">
                <h3 className="text-[20px] font-[500] tracking-[-0.2px] cursor-pointer mb-4 md:hidden"><Link href={`/item/${item.slug}`}>{name}</Link></h3>
                <div className="flex flex-row gap-[20px]">
                    {(renderPrivateImage && !item.private_preview_image_url) ?
                        <SmallLogo className="cursor-pointer object-cover h-[150px] w-[150px] max-h-[150px] min-w-[150px] block p-12 [&>path]:fill-blue2" /> :
                        <ResponsiveImage
                            onClick={() => router.push(`/item/${item.slug}`)}
                            alt={name}
                            src={renderPrivateImage ? item.private_preview_image_url : firstImage?.url}
                            imageWidth={150}
                            width={150}
                            height={150}
                            className="cursor-pointer object-cover h-[150px] w-[150px] max-h-[150px] min-w-[150px] block rounded-[8px]" />
                    }
                    <div className="flex flex-row justify-between w-full">
                        <div className="flex flex-col justify-between md:max-h-[150px]">
                            <div>
                                {item?.current_offer_transaction?.status === OfferTransaction.StatusEnum.Paid &&
                                    <span className={clsx('text-[14px] font-[500]', itsYourItem ? 'text-red4' : 'text-green3')}>
                                        {itsYourItem ? i18next.t('common:offers.awaiting-shipping') : i18next.t('common:offers.awaiting-shipping-seller')}
                                    </span>
                                }
                                {item?.current_offer_transaction?.status === OfferTransaction.StatusEnum.OfficeReceived &&
                                    <span className="text-grey15 text-[14px] font-[500]">
                                        {i18next.t('common:offers.awaiting-reauthentication')}
                                    </span>
                                }
                                {item?.current_offer_transaction?.status === OfferTransaction.StatusEnum.Reauthenticated &&
                                    <span className="text-green3 text-[14px] font-[500]">
                                        {i18next.t('common:offers.reauthenticated')}
                                    </span>
                                }
                                {item?.current_offer_transaction?.status === OfferTransaction.StatusEnum.ReauthenticatedFailed &&
                                    <span className="text-red4 text-[14px] font-[500]">
                                        {i18next.t('common:offers.reauthenticated-failed')}
                                    </span>
                                }
                                {itsYourItem && (item?.current_offer_transaction?.status === OfferTransaction.StatusEnum.ShippedToBuyer || item?.current_offer_transaction?.status === OfferTransaction.StatusEnum.Done) &&
                                    <span className="text-grey15 text-[14px] font-[500]">
                                        {i18next.t('common:offers.transaction-completed')}
                                    </span>
                                }
                                {!itsYourItem && (item?.current_offer_transaction?.status === OfferTransaction.StatusEnum.ShippedToBuyer) &&
                                    <span className="text-green3 text-[14px] font-[500]">
                                        {i18next.t('common:offers.shipped')}
                                    </span>
                                }
                                {!itsYourItem && (item?.current_offer_transaction?.status === OfferTransaction.StatusEnum.Done) &&
                                    <span className="text-grey15 text-[14px] font-[500]">
                                        {i18next.t('common:offers.transaction-completed')}
                                    </span>
                                }
                                {currentOffer && itsYourItem && currentOffer.status === Offer.StatusEnum.Pending &&
                                    <span className="text-red4 text-[14px] font-[500]">
                                        {i18next.t('common:offers.new-offer')}
                                    </span>
                                }

                                {item.check_offers_possible && itsYourItem && !currentOffer &&
                                    <span className="text-grey15 text-[14px] font-[500]">
                                        {i18next.t('common:offers.no-current-offers')}
                                    </span>
                                }

                                {comingSoon &&
                                    <div className="flex flex-row items-center gap-[6px]">
                                        <span className="text-[14px] font-[400] text-grey15 pt-[4px]">{currentBidText}:</span>
                                        <span className="text-[14px] font-[500]"><BidTimer dueDate={comingSoon?.startTime} /></span>
                                    </div>
                                }

                                {hasAuction && !item.check_offers_possible && auction?.type === Auction.TypeEnum.Auction && <div className="text-grey15 text-[14px] font-[500]">{bidTitle}&nbsp;•&nbsp;<span className="text-[14px] font-[500] text-grey15">{auction?.amount_of_bids} {i18next.t('item-text.current-bid-text.bids')}</span></div>}
                                {hasAuction && !item.check_offers_possible && auction?.type === Auction.TypeEnum.BuyNow && <div className="text-[#3069FE] text-[14px] font-[500]">{i18next.t('common:product-tile.buy-now')}</div>}
                                {offerPossible && <div className="text-[#884FD1] text-[14px] font-[500]">{i18next.t('common:product-tile.make-offer')}</div>}

                                {isLoosing && lastBid &&
                                    <div>
                                        <span className="flex items-center text-[12px] font-[500] text-red">{i18next.t('common:alerts.auction-outbid')} •&nbsp;<BidTimer dueDate={new Date(auctionEndDate ?? '')} /></span>
                                    </div>
                                }

                                {isWinning && lastBid &&
                                    <div>
                                        <span className="flex items-center text-[12px] font-[500] text-green3">{i18next.t('common:filter.winning')} •&nbsp;<BidTimer dueDate={new Date(auctionEndDate ?? '')} /></span>
                                    </div>
                                }

                                {(item.check_offers_possible && currentOffer && user && !itsYourItem && currentOffer.user_id === user?.id) ?
                                    (![Offer.StatusEnum.Rejected, Offer.StatusEnum.CounterRejected].includes(currentOffer.status) && new Date(currentOffer?.expires_at).getTime() > Date.now()) ?
                                        <div>
                                            <span className="flex items-center text-[12px] font-[500] text-grey15">{i18next.t('common:offers.offer-expires-in')}:&nbsp; <BidTimer dueDate={new Date(currentOffer?.expires_at)} /></span>
                                        </div> :
                                        [Offer.StatusEnum.Rejected, Offer.StatusEnum.CounterRejected].includes(currentOffer.status) ?
                                            <div>
                                                <span className="flex items-center text-[12px] font-[500] text-red">{i18next.t('common:offers.offer-rejected')}</span>
                                            </div>
                                            :
                                            <div>
                                                <span className="flex items-center text-[12px] font-[500] text-grey15">{i18next.t('common:offers.offer-expired')}</span>
                                            </div>
                                    :
                                    null
                                }

                                <h3 className="text-[20px] font-[500] tracking-[-0.2px] cursor-pointer hidden md:block"><Link href={`/item/${item.slug}`}>{name}</Link></h3>

                                {item.artists.length > 0 ?
                                    <Link href={item.artists[0].slug}>
                                        <span className="text-[14px] font-[500] text-black md:text-grey15 mt-[0px] block">{item.artists[0].name}</span>
                                    </Link> :
                                    <span className="text-[14px] font-[500] mt-[-4px] block">&nbsp;</span>
                                }
                            </div>
                            {item.check_offers_possible && currentMinOffer && !hasWonAuction && user && currentOffer?.user_id !== user?.id &&
                                <div>
                                    <span className="text-[14px] font-[400] text-grey15">{i18next.t('common:offers.minimum-offer')}:</span>&nbsp;
                                    <span
                                        className={clsx('text-[14px] font-[500]', { 'cursor-pointer underline': user && item.owner_id === user?.id })}
                                        onClick={() => {
                                            setShowSetMinimum(true);
                                        }}>${currentMinOffer}
                                    </span>
                                </div>
                            }
                            {item.check_offers_possible && user && currentOffer && !itsYourItem && currentOffer.user_id === user?.id &&
                                <div className="flex flex-row items-center"><span className="text-[14px] font-[400] text-grey15 pt-[4px]">{i18next.t('common:offers.your-offer')}</span>&nbsp;<span className={clsx('text-[14px] font-[500]')}><Price precision={2} number={currentOffer?.amount} /></span></div>
                            }

                            {hasAuction && !item.check_offers_possible && !isWinning && auction?.type === Auction.TypeEnum.Auction &&

                                <div className="flex flex-row items-center"><span className="text-[14px] font-[400] text-grey15 pt-[4px]">{currentBidText}:</span>&nbsp;<span className="text-[14px] font-[500]">{currentPrice}</span></div>
                            }
                            {hasAuction && !item.check_offers_possible && !isWinning && auction?.type === Auction.TypeEnum.BuyNow &&

                                <div className="flex flex-row items-center"><span className="text-[14px] font-[400] text-grey15 pt-[4px]">{i18next.t('common:product-tile.buy-now')}:</span>&nbsp;
                                    <AuctionPricePromotion auction={auction}>{({ isPromoted, amount, promotedAmount }) => {
                                        return (
                                            <span className="flex gap-1">
                                                <span className={clsx('text-[14px] font-[500]', { 'line-through text-red': isPromoted })}><Price precision={2} number={amount} /></span>
                                                {isPromoted && <span className="text-[14px] font-[500]"><Price precision={2} number={promotedAmount} /></span>}
                                            </span>
                                        );
                                    }}
                                    </AuctionPricePromotion>
                                </div>
                            }

                            {isWinning && <div className="flex flex-row items-center"><span className="text-[14px] font-[400] text-grey15 pt-[4px]">{i18next.t('item-text.current-bid-text.your-bid')}:</span>&nbsp;<span className="text-[14px] font-[500]">{currentPrice}</span></div>}
                            {isLoosing && lastUserbid && !hasWonAuction &&
                                <div className="flex flex-row items-center"><span className="text-[14px] font-[400] text-grey15 pt-[4px]">{i18next.t('item-text.current-bid-text.your-bid')}:</span>&nbsp;
                                    <span className="text-[14px] font-[500]">
                                        <Price className={clsx('text-[14px] font-[500]')} state="negative" justification="L" number={lastUserbid?.amount ?? '0'} />
                                    </span>
                                </div>
                            }
                        </div>
                        <div className="max-w-[235px] space-y-[8px] w-full mr-[16px] self-center hidden md:block">
                            <div className="flex flex-col gap-[16px]">{buttons}</div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col md:hidden mt-[32px] gap-[16px] mr-[16px]">
                    {buttons}
                </div>
            </div>
            <MinimumOfferSetModal onHide={() => { setShowSetMinimum(false); }} show={showSetMinimum} product={item} onChange={setCurrentMinOffer} />
            <ProductOffersModal
                show={showOffers}
                onHide={() => {
                    setShowOffers(false);
                    refetchOffer();
                }}
                product={item}
                currentOffer={currentOffer} />
            <ProductOffersHistoryModal show={showOffersHistoryModal} onHide={() => setShowOffersHistoryModal(false)} product={item} />
            <ProductBidModal show={showBidding} onHide={() => setShowBidding(false)} accounts={accounts} product={item} creditLimit={creditLimit} allowVipBidding={user?.allow_vip_bidding} />
            <ProductOfferModal
                offer={currentOffer}
                show={showOffering}
                onHide={() => {
                    setShowOffering(false);
                    refetchOffer();
                }}
                product={item} />
        </div>
    );
};

export default ProductRow;
/* eslint-enable react-hooks/rules-of-hooks */
